/* eslint-disable max-lines-per-function */
import { createFunctionWithTimeout } from "common/utils/core";

window.dataLayer = window.dataLayer || [];

const resetDataLayerSelectively = eventFields => {
  // reset previous data in dataLayer that match the eventFields keys
  window.dataLayer.push(function () {
    Object.entries(eventFields).forEach(([key, _value]) => {
      this.set(key, undefined);
    });
  });
};

export const sendDataLayer = (eventFields, callback = null) => {
  resetDataLayerSelectively(eventFields);
  let { genericEvent } = eventFields;
  genericEvent = genericEvent || false;

  if (callback) {
    const eventCallback = createFunctionWithTimeout(callback, 300);
    const doCallback = createFunctionWithTimeout(callback, 2000);

    // If Tag Manager fails or is blocked by browser,
    // callback must still execute.
    doCallback();

    window.dataLayer.push({
      genericEvent,
      eventCallback,
      eventTimeout: 2000,
      ...eventFields
    });
  } else {
    window.dataLayer.push({
      genericEvent,
      ...eventFields
    });
  }
};

const getTarget = ({ currentTarget }, target) => {
  if (target && "getAttribute" in target) {
    return target.getAttribute("target");
  }

  if (currentTarget && "getAttribute" in currentTarget) {
    return currentTarget.getAttribute("target");
  }

  return undefined;
};

/**
 * Wrapper function for sending GA with redirect to some page
 * Works the same as sendDataLayer function but no callback needed, and there is check for CTRL/CMD keys
 * refs https://dekeo.atlassian.net/browse/BRAVO-1148
 * @param event - original event
 * @param gaFields - Google Analytics fields
 * @param target - if you want to pass custom target and not to rely on event's one
 */
export const sendDataLayerOnLinkClickEvent = (event, gaFields, target = null) => {
  const { href } = target || event.currentTarget;
  const isBlank = getTarget(event, target) === "_blank";
  const shouldNotPreventEvent = event.ctrlKey || event.metaKey || isBlank;

  if (shouldNotPreventEvent) {
    sendDataLayer(gaFields);
  } else {
    event.preventDefault();
    // eslint-disable-next-line no-return-assign
    sendDataLayer(gaFields, () => (window.location = href));
  }
};
