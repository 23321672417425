/* eslint-disable import/no-extraneous-dependencies */
import { track as mixpanelTrack } from "common/utils/mixpanel/tracker";
import { track as clarityTrack } from "common/utils/clarity/tracker";
import { track as rudderstackTrack } from "common/utils/rudderstack/tracker";

export { sendDataLayer, sendDataLayerOnLinkClickEvent } from "common/utils/ga";
export { registerMixpanel } from "common/utils/mixpanel/tracker";
export { initMixpanelEvents } from "common/utils/mixpanel/init-mixpanel-events";

export const track = (event, rest, options) => {
  mixpanelTrack(event, rest, options);
  clarityTrack(event, rest);
};

export const trackRudderstack = (event, rest) => {
  rudderstackTrack(event, rest);
};
