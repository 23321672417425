const { log, info, error, warn, groupCollapsed, groupEnd } = console;

const noop = () => {};

export default {
  log: (log && log.bind(console)) || noop,
  info: (info && info.bind(console)) || noop,
  error: (error && error.bind(console)) || noop,
  warn: (warn && warn.bind(console)) || noop,
  groupCollapsed: (groupCollapsed && groupCollapsed.bind(console)) || noop,
  groupEnd: (groupEnd && groupEnd.bind(console)) || noop
};
