/* eslint-disable max-lines-per-function */
import UAParser from "ua-parser-js";
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from "web-vitals/attribution";
import { EventBus, Events } from "jiffy-store";
import { track } from "jiffy-analytics";
import { readCookie, writeCookie } from "common/utils/cookie";
import { isLocalStorageAvailable } from "common/utils/browser";
import { isProduction } from "common/utils/env";

export class MetricsService {
  #WEB_VITALS_TRACKING = {
    threshold: 30, // only ~30% of sessions will be tracked
    cookieName: "web_vitals_tracking_value",
    cookieAge: 60 * 60 * 24 // 1 day
  };

  #FIRST_PERFORMANCE_EVENT_TRACKING = {
    keyName: "is_very_first_page_load",
    cookieAge: 60 * 60 * 24 // 1 day
  };

  constructor() {
    this.extra = {};
    this.metrics = {};
    this.attribution = {};
    this.metricsSent = false;
    this.parser = new UAParser();
    this.$webVitalsPack = document.querySelector("#web-vitals-pack");

    if (!this.$webVitalsPack) return;
    if (!this.isWebVitalsCookieValuePresent) this.webVitalsCookieValue = Math.random();
    if (!this.shouldTrack) return;

    this.os = this.parser.getOS();
    const browser = this.parser.getBrowser().name?.toLowerCase();

    if (this.os.name?.toLowerCase() === "ios" || browser === "safari") {
      this.metricsThreshold = 2;
    } else if (browser === "chrome" || window.chrome) {
      this.metricsThreshold = 6;
    } else {
      this.metricsThreshold = 4;
    }

    const { checkoutType, currentUser, locationSelected, pageType, infrastructure } =
      this.$webVitalsPack.dataset;

    const getMetaBooleanValue = name => {
      const content = document.head.querySelector(`meta[name=${name}]`)?.content;
      if (content === "true") return true;
      if (content === "false") return false;
      return undefined;
    };
    const isMastheadCached = getMetaBooleanValue("is-masthead-cached");
    let isMastheadMenuCached = getMetaBooleanValue("is-masthead-menu-cached");
    isMastheadMenuCached =
      typeof isMastheadMenuCached === "undefined"
        ? undefined
        : isMastheadCached || isMastheadMenuCached;

    this.staticProperties = {
      ...this.getDeviceInfo(),
      checkout_type: checkoutType,
      is_location_selected: locationSelected === "true",
      is_logged_in: currentUser === "true",
      is_mobile_app: !!window.MOBILE_APP,
      page_type: pageType,
      is_cached: getMetaBooleanValue("isCached"),
      is_es_products_cached: getMetaBooleanValue("is-es-products-cached"),
      is_masthead_cached: isMastheadCached,
      is_masthead_menu_cached: isMastheadMenuCached,
      is_aside_filter_cached: getMetaBooleanValue("is-aside-filter-cached"),
      sent_asynchronously: true,
      infrastructure
    };

    onCLS(({ attribution, value }) => this.handleMetric("cls", { attribution, value }));
    onFCP(({ attribution, value }) => this.handleMetric("fcp", { attribution, value }));
    onFID(({ attribution, value }) => this.handleMetric("fid", { attribution, value }));
    onINP(({ attribution, value }) => this.handleMetric("inp", { attribution, value }));
    onLCP(({ attribution, value }) => this.handleMetric("lcp", { attribution, value }));
    onTTFB(({ attribution, value }) => this.handleMetric("ttfb", { attribution, value }));

    EventBus.on(Events.PUSHED_WEB_VITALS_METRICS, ({ target }) => {
      this.extra = { ...this.extra, ...target };
    });

    if (navigator.sendBeacon) {
      const terminationEvent = "onpagehide" in window ? "pagehide" : "unload";
      window.addEventListener(terminationEvent, () => {
        if (!this.metricsSent && Object.entries(this.metrics).length > 0) {
          this.sendToAnalytics(true);
        }
      });
    }
  }

  get webVitalsTracking() {
    return this.#WEB_VITALS_TRACKING;
  }

  get isWebVitalsCookieValuePresent() {
    return this.webVitalsCookieValue !== null;
  }

  get webVitalsCookieValue() {
    const value = readCookie(this.webVitalsTracking.cookieName);
    return Number(value) || null;
  }

  set webVitalsCookieValue(value) {
    writeCookie(this.webVitalsTracking.cookieName, value, this.webVitalsTracking.cookieAge);
  }

  get shouldTrack() {
    if (!isProduction()) return true;
    if (!this.isWebVitalsCookieValuePresent) return false;

    return this.webVitalsCookieValue < this.webVitalsTracking.threshold / 100;
  }

  get firstPerformanceEventTracking() {
    return this.#FIRST_PERFORMANCE_EVENT_TRACKING;
  }

  get isVeryFirstPageLoad() {
    if (isLocalStorageAvailable()) {
      return window.localStorage.getItem(this.firstPerformanceEventTracking.keyName) !== "false";
    }

    return readCookie(this.firstPerformanceEventTracking.keyName) !== "false";
  }

  set isVeryFirstPageLoad(value) {
    if (isLocalStorageAvailable()) {
      window.localStorage.setItem(this.firstPerformanceEventTracking.keyName, value);
    } else {
      writeCookie(
        this.firstPerformanceEventTracking.keyName,
        value,
        this.firstPerformanceEventTracking.cookieAge
      );
    }
  }

  handleMetric(name, { attribution, value }) {
    this.metrics[name] = name === "cls" ? parseFloat(value.toFixed(8)) : Math.round(value);

    // eslint-disable-next-line smells/no-switch
    switch (name) {
      case "cls":
        this.attribution[`${name}_debug_target`] = attribution.largestShiftTarget;
        break;
      case "fid":
        this.attribution[`${name}_debug_target`] = attribution.eventTarget;
        break;
      case "inp":
        this.attribution[`${name}_debug_target`] = attribution.eventTarget;
        this.attribution[`${name}_debug_event_type`] = attribution.eventType;
        this.attribution[`${name}_debug_load_state`] = attribution.loadState;
        break;
      case "lcp":
        this.attribution[`${name}_debug_target`] = attribution.element;
        break;
      default:
        break;
    }

    if (!this.metricsSent && Object.entries(this.metrics).length >= this.metricsThreshold) {
      this.sendToAnalytics();
      this.metricsSent = true;
    }
  }

  sendToAnalytics(async = false) {
    this.extra[this.firstPerformanceEventTracking.keyName] = this.isVeryFirstPageLoad;

    if (this.isVeryFirstPageLoad) {
      this.isVeryFirstPageLoad = false;
    }

    const properties = {
      ...this.metrics,
      ...this.attribution,
      ...this.extra,
      ...this.staticProperties
    };

    if (async) {
      track("performance", properties, { transport: "sendBeacon" });
    } else {
      properties.sent_asynchronously = false;
      track("performance", properties);
    }
  }

  getDeviceInfo() {
    const device = this.parser.getDevice();
    return {
      device_vendor: device.vendor,
      device_model: device.model,
      os: this.os.name,
      os_version: this.os.version
    };
  }
}

export default new MetricsService();
