export const readCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return undefined;
};

/*
 * @function writeCookie
 * @description Write a cookie
 * @param {string} key - The key of the cookie
 * @param {string} value - The value of the cookie
 * @param {number} seconds - The number of seconds the cookie should last. max-age=3600 - 1 hour
 */
export const writeCookie = (key, value, age = 3600) => {
  document.cookie = `${key}=${value}; max-age=${age}; path=/`;
};

export const writePersistentCookie = (key, value) => {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  document.cookie = `${key}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const checkOnePageCookie = cookieName => {
  const currentLocation = window.location.href;
  const { executed = false, initialLocation = currentLocation } = JSON.parse(
    readCookie(cookieName) || "{}"
  );

  // If the user has already dismissed the popup/tooltip/modal, don't show it again on other pages
  if (initialLocation !== currentLocation) {
    writeCookie(cookieName, JSON.stringify({ executed: true }));
    return true;
  }

  if (!executed) {
    writeCookie(cookieName, JSON.stringify({ initialLocation: currentLocation }));
  }

  return executed;
};

export const deleteCookie = key => {
  writeCookie(key, "", -1);
};

export default {};
