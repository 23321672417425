function goBack() {
  if (window.history.length) {
    window.history.go(-1);
  }
}

export const isNewTab = () => window.history.length === 1;

export const isIE = () => !!window.MSInputMethodContext && !!document.documentMode;

export const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isFirefox = () => window.navigator.userAgent.toLowerCase().includes("firefox");

export const isAndroid = () => window.navigator.userAgent.toLowerCase().indexOf("android") > -1;

export const isCrawler = () =>
  /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

export const browserType = () => {
  let browser;
  if (window.chrome && window.chrome.webstore) {
    browser = "chrome";
  } else if (!document.documentMode && window.StyleMedia) {
    browser = "microsoft";
  } else {
    browser = "browser";
  }
  return browser;
};

export const redirectTo = (url, asLink = false) => {
  if (asLink) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
  } else {
    window.location = url;
  }
};

export const getWindowWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const goBackOrDefaultUrl = defaultUrl => {
  const emptyHistory = window.history.length <= 2;
  const userCameFromOtherDomain = document.referrer.split("/")[2] !== window.location.host;

  if (emptyHistory || userCameFromOtherDomain) {
    redirectTo(defaultUrl);
  } else {
    goBack();
  }
};

export const pushUrl = (url, replace = false) => {
  if (replace) {
    window.history.replaceState({}, "", url);
  } else {
    window.history.pushState({}, "", url);
  }
};

export const dispatchWindowResize = () => {
  window.dispatchEvent(new Event("resize"));
};

export const reload = () => window.location.reload();

export const isMediumDeviceAndUp = () => getWindowWidth() >= 768;

export const currentUrl = () => window.location.href;

export const isPhone = () => getWindowWidth() < 768;

export const isMobileBrowser = () => document.documentElement.classList.contains("device-mobile");

export const isMobile = () => getWindowWidth() < 992;

export const isDesktop = () => getWindowWidth() > 1280;

export const isTouchDevice = () =>
  "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export const hasQueryParam = queryParam =>
  new URLSearchParams(window.location.search).has(queryParam);

export const getQueryParams = () =>
  [...new URLSearchParams(window.location.search)].reduce((acct, [key, value]) => {
    if (acct[key]) {
      if (Array.isArray(acct[key])) {
        acct[key].push(value);
      } else {
        acct[key] = [acct[key], value];
      }
      return acct;
    }
    return { ...acct, [key]: value };
  }, {});

export const upsertQueryParam = (key, value, options = { redirect: true, replace: false }) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  if (value) searchParams.set(key, value);
  const { protocol, host, pathname } = window.location;
  const newUrl = `${protocol}//${host}${pathname}?${searchParams.toString()}`;

  if (options.redirect) {
    redirectTo(newUrl);
  } else {
    pushUrl(newUrl, options.replace);
  }
};

export const removeQueryParam = param => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState({}, document.title, url);
};

export const isLocalStorageAvailable = () => {
  const test = `test${Math.random()}`;

  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const cachedIsLocalStorageAvailable = isLocalStorageAvailable();

export function requestLocalStorage() {
  if (cachedIsLocalStorageAvailable) {
    return window.localStorage;
  }

  return undefined;
}
