export const compose = (...fns) =>
  fns.reduceRight(
    (prevFn, nextFn) =>
      (...args) =>
        nextFn(prevFn(...args)),
    value => value
  );

export const cloneDeep = obj => JSON.parse(JSON.stringify(obj));

export const isObject = obj => obj && typeof obj === "object" && !Array.isArray(obj);

export const dropEmptyKeys = obj =>
  Object.keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined)
    .reduce(
      (newObj, k) =>
        isObject(obj[k])
          ? Object.assign(newObj, { [k]: dropEmptyKeys(obj[k]) })
          : Object.assign(newObj, { [k]: obj[k] }),
      {}
    );

export const createFunctionWithTimeout = (callback, optTimeout) => {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, optTimeout || 1000);

  return fn;
};

export const mergeDeep = (target, source) => {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};
