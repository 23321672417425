import { tryParse } from "common/utils/json";
import { track } from "./tracker";

const $getDtfProductDetails = () => document.getElementById("vue-product-details-dtf");

const trackedDtfProductProps = () => {
  const $details = $getDtfProductDetails();
  if (!$details) return {};

  // Convert dataset keys to snake_case
  return Object.fromEntries(
    Object.entries($details.dataset).map(([key, value]) => [
      key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase(),
      value
    ])
  );
};

// eslint-disable-next-line import/prefer-default-export
export const initMixpanelEvents = (
  containerSelector = "body",
  elementSelector = "[data-mixpanel-event][data-mixpanel-listen]"
) => {
  document.querySelectorAll(`${containerSelector} ${elementSelector}`).forEach($el => {
    const { mixpanelLoaded, mixpanelListen, mixpanelEvent, mixpanelData } = $el.dataset;
    if (mixpanelLoaded === "true") return;
    $el.dataset.mixpanelLoaded = true;

    const eventTypes = mixpanelListen.split("|");
    const trackingNames = mixpanelEvent.split("|");
    const trackingData = mixpanelData?.split("|") || [];

    eventTypes.forEach((type, index) => {
      const name = trackingNames[index] || trackingNames[0];
      const originalData = trackingData[index] || trackingData[0];

      let eventData = originalData ? tryParse(originalData) : {};
      if (name.startsWith("pdp_video_")) {
        eventData = { ...eventData, ...trackedDtfProductProps() };
      }

      $el.addEventListener(type, () => {
        track(name, eventData);
      });
    });
  });
};
