// eslint-disable-next-line import/prefer-default-export
export const track = (eventType, rest) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "rudderstack_event",
      eventData: {
        event_type: eventType,
        ...rest
      }
    });
  }
};

const checkoutSteps = ["order_checkout", "review_your_order", "checkout_complete"];
const $getOrderNumberContainer = () => document.querySelector(".js-order-number-container");
const $getCheckoutContainer = () => document.getElementById("checkout");
const $getActiveShipmentRadioButtons = () =>
  document.querySelectorAll(
    '.mcu-order-shipping-speed-selector__radio-wrapper.active input[type="radio"]'
  );

export const trackCheckoutStepViewed = stepName => {
  const stepNumber = checkoutSteps.indexOf(stepName) + 1;
  const $checkoutContainer = $getCheckoutContainer();
  const $orderNumberContainer = $getOrderNumberContainer();
  let shippingMethods = [];

  if (stepNumber === 0) return;

  if ($checkoutContainer) {
    shippingMethods = JSON.parse($checkoutContainer.dataset.checkoutData)?.shipping_methods;
  } else {
    shippingMethods = Array.from($getActiveShipmentRadioButtons()).map(radio => radio.value);
  }

  track("Checkout Step Viewed", {
    store_id: $orderNumberContainer?.dataset?.storeId,
    is_app: Boolean(window.MOBILE_APP),
    step_name: stepName,
    step_number: stepNumber,
    shipping_methods: shippingMethods,
    order_number: $orderNumberContainer?.dataset?.orderNumber,
    cart_name: $orderNumberContainer?.dataset?.cartName
  });
};

export const trackCheckoutStepCompleted = (stepName, clickTarget = "") => {
  const stepNumber = checkoutSteps.indexOf(stepName) + 1;
  const $checkoutContainer = $getCheckoutContainer();
  const $orderNumberContainer = $getOrderNumberContainer();
  let shippingMethods = [];

  if (stepNumber === 0) return;

  if ($checkoutContainer) {
    shippingMethods = JSON.parse($checkoutContainer.dataset.checkoutData)?.shipping_methods;
  } else {
    shippingMethods = Array.from($getActiveShipmentRadioButtons()).map(radio => radio.value);
  }

  track("Checkout Step Completed", {
    store_id: $orderNumberContainer?.dataset?.storeId,
    is_app: Boolean(window.MOBILE_APP),
    step_name: stepName,
    step_number: stepNumber,
    shipping_methods: shippingMethods,
    click_target: clickTarget,
    order_number: $orderNumberContainer?.dataset?.orderNumber,
    cart_name: $orderNumberContainer?.dataset?.cartName,
    delivery_date_changed: null,
    expected_delivery_date: null
  });
};

export const trackProductViewed = async eventData => {
  const $orderNumberContainer = $getOrderNumberContainer();

  track("Product Viewed", {
    store_id: $orderNumberContainer?.dataset?.storeId,
    ...eventData
  });
};
